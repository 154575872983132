import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vue2Editor from "vue2-editor";
import firebase from "firebase/app"
import "firebase/auth"

Vue.use(Vue2Editor);

Vue.config.productionTip = false;

let app;

// 页面加载的比firebase验证快，所以需要加个这个验证
firebase.auth().onAuthStateChanged(()=>{
  if(!app){
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
})

