import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyBtOAMlZ8czIHEkwlxIZYhavXLneXJN3xE",
  authDomain: "vincentblogs-7d91c.firebaseapp.com",
  projectId: "vincentblogs-7d91c",
  storageBucket: "vincentblogs-7d91c.appspot.com",
  messagingSenderId: "993175188966",
  appId: "1:993175188966:web:dcc12ca828f95861859227"
};

const firebaseApp = firebase.initializeApp(firebaseConfig)

const timestamp = firebase.firestore.FieldValue.serverTimestamp

export {timestamp}
export default firebaseApp.firestore()