import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase/app'
import "firebase/auth"
import db from "../firebase/firebaseInit"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    blogPosts:[],
    postLoaded: null,
    editPost: null,
    user: null,
    profileAdmin: null,
    profileEmail: null,
    profileFirstName: null,
    profileLastName: null,
    profileUsername: null,
    profileId: null,
    profileInitials: null,

    blogHTML: "Write your blog title here...",
    blogTitle: "",
    blogPhotoName: "",
    blogPhotoFileURL: null,
    blogPhotoPreview: null,
  },
  getters:{
    blogPostsFeed(state){
      return state.blogPosts.slice(0, 2)
    },
    blogPostsCards(state){
      return state.blogPosts.slice(2, 6)
    }
  },
  mutations: {
    toggleEditPost(state, payload){
      state.editPost = payload
      console.log(state.editPost)
    },
    setProfileInfo(state, payload){
      state.profileId = payload.id
      state.profileEmail = payload.data().email
      state.profileFirstName = payload.data().firstName
      state.profileLastName = payload.data().lastName
      state.profileUsername = payload.data().username
    },
    setProfileInitials(state){
      state.profileInitials = 
        state.profileFirstName.match(/(\b\S)?/g).join("") + 
        state.profileLastName.match(/(\b\S)?/g).join("")
    },
    updateUser(state, payload){
      state.user = payload
    },
    changeFirstName(state, payload){
      state.profileFirstName = payload
    },
    changeLastName(state, payload){
      state.profileLastName = payload
    },
    changeUsername(state, payload){
      state.profileUsername = payload
    },
    changeEmail(state, payload){
      state.profileEmail = payload
    },
    setProfileAdmin(state, payload){
      state.profileAdmin = payload
      console.log(state.profileAdmin)
    },
    newBlogPost(state,payload){
      state.blogHTML = payload
    },
    updateBlogTitle(state, payload){
      state.blogTitle = payload
    },
    fileNameChange(state, payload){
      state.blogPhotoName = payload
    },
    createFileURL(state, payload){
      state.blogPhotoFileURL = payload
    },
    openPhotoPreview(state){
      state.blogPhotoPreview = !state.blogPhotoPreview
    },
    filterBlogPost(state, payload){
      state.blogPosts = state.blogPosts.filter(post=>post.blogID !== payload)
    },
    setBlogState(state, payload){
      state.blogTitle = payload.blogTitle
      state.blogHTML = payload.blogHTML
      state.blogPhotoFileURL = payload.blogCoverPhoto
      state.blogPhotoName = payload.blogCoverPhotoName
    }
  },
  actions: {
    async getCurrentUser({commit}, user){
      const database = await db.collection('users').doc(firebase.auth().currentUser.uid)
      const dbResult = await database.get()
      commit("setProfileInfo", dbResult)
      commit("setProfileInitials")
      const token = await user.getIdTokenResult()
      const admin = await token.claims.admin
      commit('setProfileAdmin', admin)
    },
    async updateUserSettings({commit, state}){
      const database = await db.collection('users').doc(state.profileId)
      await database.update({
        firstName: state.profileFirstName,
        lastName: state.profileLastName,
        username: state.profileUsername,
        email: state.profileEmail,
      })
      commit("setProfileInitials")

    },
    async getPost({state}){
      const dataBase = db.collection('blogPosts').orderBy('date', 'desc')
      const dbResults = await dataBase.get()
      dbResults.forEach((doc)=>{
        if(!state.blogPosts.some(post=>post.blogID === doc.id)){
          const data = {
            blogID: doc.data().blogID,
            blogHTML: doc.data().blogHTML,
            blogCoverPhoto: doc.data().blogCoverPhoto,
            blogTitle: doc.data().blogTitle,
            blogDate: doc.data().date,
            blogCoverPhotoName: doc.data().blogCoverPhotoName,
          }
          state.blogPosts.push(data)
        }
      })
      state.postLoaded = true
      console.log(state.blogPosts)
    },
    async deletePost({commit}, payload){
      const getPost = await db.collection("blogPosts").doc(payload)
      await getPost.delete()
      commit('filterBlogPost', payload)
    },
    async updatePost({commit, dispatch}, payload){
      commit('filterBlogPost', payload)
      await dispatch("getPost")
    }
  },
  modules: {
  }
})
